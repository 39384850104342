import React from 'react'
import Header from '../layout/header'
import BookAppointmentForm from '../common/BookAppointmentForm'
import Footer from '../layout/footer'
import appointmentPic from '../../assets/homeImg/AppointmentPic.png'

const BookAppointment = () => {
    return (
        <div>
            <Header />
            <BookAppointmentForm appointmentPic={appointmentPic} isReversed />
            <Footer />
        </div>
    )
}

export default BookAppointment
