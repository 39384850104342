import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import CalendarIcon from '../../assets/octiconCalendar.png'
import ProfileIcon from '../../assets/ProfImg.png'
import PhoneIcon from '../../assets/Mobile.png'
import ButtonGradient from '../common/buttonGradient'
import moment from 'moment'

const BookAppointmentForm = ({ hideFogEffect, isReversed, appointmentPic }) => {
    const [categories, setCategories] = useState([
        'Weight Management',
        'Skin',
        'Hair',
        'Cosmetic Dentistry',
        'Cosmetic Procedures'
    ]) // Mock data for categories
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [formData, setFormData] = useState({
        service: '',
        userName: '',
        phone: '',
        date: '',
    })
    const [errors, setErrors] = useState(false)

    const baseUrl = 'https://kozacare.org/v1/api/koza/'

    useEffect(() => {
        // Commenting out API call
        // const fetchData = async () => {
        //     const response = await fetch(`${baseUrl}services`)
        //     const data = await response.json()
        //     console.log(data)
        //     setCategories(data?.data)
        // }
        // fetchData()
    }, [])

    const validateForm = () => {
        const errors = {}

        if (!formData?.userName) {
            errors.userName = 'Name is required'
        } else if (!/^[A-Za-z]+$/.test(formData.userName)) {
            errors.userName = 'Name can only contain alphabets'
        }
        if (!formData?.phone) {
            errors.phone = 'Phone number is required'
        } else if (!/^\d+$/.test(formData.phone)) {
            errors.phone = 'Phone number must contain only digits'
        } else if (formData.phone.length !== 10) {
            errors.phone = 'Mobile number should be 10 digits.'
        }
        if (!formData?.date) {
            errors.date = 'Date is required'
        }
        if (!formData?.service) {
            errors.service = 'Service is required'
        }

        return errors
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const errors = validateForm()
        if (Object.keys(errors).length === 0) {
            // Comment out the API call
            // fetch(`${baseUrl}email`, {
            //     method: 'POST',
            //     headers: {
            //         Accept: 'application/json',
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         service_name: formData?.service,
            //         customer_name: formData?.userName,
            //         contact: formData?.phone,
            //         appointment_date: formData?.date,
            //     }),
            // })
            //     .then(() => {
            //         setErrors({})
            //         setFormData({})
            //         setFormSubmitted(true)
            //     })
            //     .catch((error) => {
            //         console.log(error)
            //     })

            // Simulate a successful form submission
            setErrors({})
            setFormData({})
            setFormSubmitted(true)
        } else {
            setErrors(errors)
        }
    }

    const handleChange = async (fieldName, value) => {
        setFormData({
            ...formData,
            [fieldName]: value,
        })
        setErrors({
            ...errors,
            [fieldName]: false,
        })
    }

    return (
        <>
            <div id="book-appointment" className="sm:pb-24 lg:py-5">
                <div className="mt-10 text-center relative ">
                    <p className="text-[28px] lg:text-[36px] xl:text-[40px] font-semibold leading-[50px] text-[#060606]">
                        Book Appointment
                    </p>
                    <p className="text-[#3e3e3e] text-[14px] lg:text-[18px] font-normal my-3">
                        Please fill the form below and expedite your
                        consultation process.
                    </p>
                    <div className="hidden xl:block absolute right-24 top-5 italic text-[#ccc] font-normal text-[32px] leading-10">
                        <p className="pr-[108px]">Let’ s</p>
                        <p>Get in Touch</p>
                    </div>
                </div>
                <div className="grid sm:grid-cols-2 gap-2 justify-stretch py-4 ">
                    <div className="flex md:block md:translate-y-[20%] gap-x-2 p-2 h-[100%] w-full md:w-[90%] justify-center items-center">
                        <form className="px-6 " onSubmit={handleSubmit}>
                            <div className="flex flex-col justify-center gap-3 ">
                                <div
                                    className={cx(
                                        'relative border rounded-md p-3 border-[#D3D3D3]',
                                        {
                                            'border-[1px] border-[#af4141]':
                                                errors?.service,
                                        }
                                    )}
                                >
                                    <select
                                        name="service"
                                        className="w-full focus:outline-none opacity-50 capitalize"
                                        value={formData.service || ''}
                                        onChange={(e) =>
                                            handleChange(
                                                'service',
                                                e.target.value
                                            )
                                        }
                                    >
                                        <option value="">
                                            Select Category
                                        </option>
                                        {categories.map((data, index) => (
                                            <option key={index}>{data}</option>
                                        ))}
                                    </select>
                                </div>
                                {errors.service && (
                                    <span className="text-[#af4141]">
                                        {errors.service}
                                    </span>
                                )}

                                <div
                                    className={cx(
                                        'relative border rounded-md p-3 border-[#D3D3D3]',
                                        {
                                            'border-[1px] border-[#af4141]':
                                                errors?.userName,
                                        }
                                    )}
                                >
                                    <input
                                        className="outline-none h-5 w-full "
                                        type="text"
                                        placeholder="Your Name"
                                        name="userName"
                                        value={formData.userName || ''}
                                        onChange={(e) =>
                                            handleChange(
                                                'userName',
                                                e.target.value
                                            )
                                        }
                                    />
                                    <img
                                        src={ProfileIcon}
                                        alt="ProfileIcon"
                                        className="absolute z-10 right-0 top-0 p-5"
                                    />
                                </div>
                                {errors.userName && (
                                    <span className="text-[#af4141]">
                                        {errors.userName}
                                    </span>
                                )}
                                <div
                                    className={cx(
                                        'relative border rounded-md p-3 border-[#D3D3D3]',
                                        {
                                            'border-[1px] border-[#af4141]':
                                                errors.phone,
                                        }
                                    )}
                                >
                                    <input
                                        className="outline-none h-5 w-full  "
                                        type="number"
                                        name="Phone"
                                        placeholder="Phone Number"
                                        value={formData.phone || ''}
                                        onChange={(e) =>
                                            handleChange(
                                                'phone',
                                                e.target.value
                                            )
                                        }
                                    />
                                    <img
                                        src={PhoneIcon}
                                        alt="PhoneIcon"
                                        className="absolute z-10 right-0 top-0 py-4 px-4  "
                                    />
                                </div>
                                {errors.phone && (
                                    <span className="text-[#af4141]">
                                        {errors.phone}
                                    </span>
                                )}
                                <div
                                    className={cx(
                                        'relative border rounded-md p-3 border-[#D3D3D3]',
                                        {
                                            'border-[1px] border-[#af4141]':
                                                errors?.date,
                                        }
                                    )}
                                >
                                    <input
                                        className="outline-none h-5 w-full px-1 opacity-50 "
                                        type="date"
                                        name="date"
                                        placeholder="Date of Appointment"
                                        onChange={(e) =>
                                            handleChange('date', e.target.value)
                                        }
                                        value={formData.date || ''}
                                        min={moment().format('YYYY-MM-DD')}
                                    />
                                </div>
                                {errors.date && (
                                    <span className="text-[#af4141]">
                                        {errors.date}
                                    </span>
                                )}
                                <div className="flex justify-start py-5">
                                    <button
                                        type="submit"
                                        className="bg-gradient-to-r from-[#39063F] from-2.3% via-[#7C3057] via-72.44% to-[#602639] to-97.51% text-[#fff] text-[12px] font-medium  px-4 py-3 rounded-2xl"
                                    >
                                        BOOK APPOINTMENT NOW
                                    </button>
                                </div>
                            </div>
                        </form>

                        {formSubmitted && (
                            <div className="text-center text-[24px] text-[#39063F] font-semibold  ">
                                Your appointment has been booked successfully.
                            </div>
                        )}
                    </div>

                    <div
                        className={cx(
                            'flex flex-wrap object-contain overflow-hidden justify-center relative p-10',
                            {
                                'row-start-1 px-2 md:px-0': isReversed,
                            }
                        )}
                    >
                        <div>
                            <img
                                src={appointmentPic}
                                alt="pic"
                                className="object-contain sm:px-0 px-3"
                            />
                            <div
                                className={cx('m-0 p-0', {
                                    hidden: hideFogEffect,
                                })}
                            >
                                <div className="absolute right-0 w-20 lg:w-36 h-[330px] md:h-[200px] xl:h-[446px] bg-gradient-to-l from-[#fff] from-30%"></div>
                                <div className="absolute bottom-0 h-10 md:h-36 w-[500px] md:w-[390px] lg:w-[490px] xl:w-[720px] bg-gradient-to-t from-[#fff] from-30%"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BookAppointmentForm
